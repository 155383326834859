import React from "react";
import { Link } from "gatsby";

import Layout from "../components/Layout";
import SEO from "../components/SEO";

const EducationalCenterPage: React.FC = () => (
    <Layout>
        <SEO title="Educational Center" />

        <section className="section">
            <div className="container content">
                <h1 className="title is-1">Educational Center</h1>

                <p>
                    We're looking for assistance with opening a new kind of{" "}
                    <a href="https://codeselfstudy.com/school/">
                        self-directed educational center for programmers
                    </a>{" "}
                    that is autism-friendly and very cheap (or free, for those
                    who can't afford it).
                </p>
                <p>
                    We already have over 5,000 local members in our larger
                    programming community (which is not autism-specific). We're
                    planning to expand that with a physical space where anyone
                    can study programing seven days per week at a low cost (or
                    free).
                </p>
                <p>
                    All programming languages and levels of experience are
                    welcome. The more advanced members mentor the beginners in a
                    guided self-study format. We've been running the meetups for
                    six years and the model works.
                </p>
                <h2 className="title is-2">Features:</h2>
                <ul>
                    <li>
                        An alternative to programming bootcamps that provides
                        access to a high-quality programming education without
                        requiring large amounts of money or fixed time
                        commitment.
                    </li>
                    <li>
                        Open to everyone, with a special emphasis on being an
                        autism-friendly environment.{" "}
                        <em>
                            The educational center will not be{" "}
                            <strong>only</strong> for autistic people -- that is
                            just one significant component of its outreach,
                            because it is the area that I am most familiar with.
                        </em>
                    </li>
                    <li>
                        Provide additional resources and training for autistic
                        people, including mentorship, low-stress social
                        environments, networking opportunities, and more.
                    </li>
                    <li>
                        Non-exclusive, without difficult barriers to entry. It's
                        too difficult to get into high-quality coding education
                        programs for many people -- not necessarily because they
                        don't have the potential to find success in the tech
                        industry, but because the existing models are not ideal
                        for everyone. We already have an alternate model with{" "}
                        <a href="https://codeselfstudy.com/">Code Self Study</a>{" "}
                        (5,000+ members) that is working well. Our goal is to
                        remove common barriers and hurdles that prevent people
                        from getting into the industry.
                    </li>
                    <li>
                        No time limit for attending -- stay as long as you want,
                        even after you get a job or start an entrepreneurial
                        project. (Experienced members mentor the beginners.)
                    </li>
                    <li>
                        No need to study programming on your own for a year
                        before attending like with most bootcamps. Just show up
                        and instantly find assistance and mentorship.
                    </li>
                    <li>
                        Low cost, like a hackerspace (though not a hackerspace)
                        with subsidized membership for people who can't afford
                        to pay. (no barriers)
                    </li>
                    <li>Emphasis on self-study with experienced mentors.</li>
                    <li>
                        Open to all programming languages and levels of
                        experience.
                    </li>
                    <li>
                        Encouraging entrepreneurship in addition to job
                        assistance.
                    </li>
                    <li>
                        Emphasis on creating an awareness about the implications
                        of what people build, not just how to build things.
                    </li>
                </ul>
                <p>
                    We've been working on this idea since 2013 and have a
                    working model in the Code Self Study group. We just need a
                    physical location in order to take it to the next level. The
                    next step is to raise funding for the initial opening, which
                    I hope will take place in Downtown Berkeley.
                </p>
                <p>
                    For more information, or to find out how you can help,
                    please <Link to="/contact/">send us an email</Link>.
                </p>
            </div>
        </section>
    </Layout>
);

export default EducationalCenterPage;
